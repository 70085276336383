<div class="room-card">
  <div class="room-card-image">
    @if (room.mainPhoto) {
      <img [ngSrc]="room.mainPhoto" fill alt="" />
    }
  </div>
  <div class="room-info">
    <div>
      <p class="ui-heading-4">{{ room.name }}</p>
      <p class="ui-label-l">
        {{ room.siteName }} {{ room.buildingName }} {{ room.floorName }}
      </p>
    </div>
    <div class="bottom-line">
      <div
        class="text-with-icon"
        [rdsTooltip]="
          'Maximum room capacity up to ' + room.capacity + ' people'
        ">
        <rds-icon namespace="outlined" icon="user_group" />
        @if (room.minCapacity) {
          <p class="ui-label-m-bold c-600-grey-2">
            {{ room.minCapacity }}-{{ room.capacity }} people
          </p>
        } @else {
          <p class="ui-label-m-bold c-600-grey-2">
            Max {{ room.capacity }} people
          </p>
        }
      </div>
      @if (room.features) {
        <div class="text-with-icon" [rdsTooltip]="features">
          <rds-icon namespace="outlined" icon="task" />
          <p class="ui-label-m-bold c-600-grey-2">
            {{ room.features.length }} items
          </p>
        </div>
      }
    </div>
  </div>
</div>
