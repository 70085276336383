<a rds-link standalone size="m" [routerLink]="''" class="back-nav">
  <rds-icon namespace="filled" icon="arrow_left"></rds-icon>
  Back to search results
</a>

@let roomData = roomDetails();

@if (isRoomLoading() || isBookingRequestLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
}

@if (roomData) {
  <div class="header">
    <p class="ui-heading-1-bold">{{ roomData.roomName }}</p>
  </div>
}

<rds-tabs>
  <rds-tab label="Event details">
    <div class="card-container">
      <div class="form-container">
        <bus-room-booking-form
          [roomDetails]="roomData"
          [roomLayouts]="roomLayouts()"
          (bookingRequestFormData)="createRoomBooking($event)"
          (cancelHandler)="cancel()">
        </bus-room-booking-form>
      </div>

      @if (roomData) {
        <bus-room-details-card
          [roomDetails]="roomData"
          [roomLayouts]="null"></bus-room-details-card>
      }
    </div>
  </rds-tab>
  <rds-tab label="Calendar">
    <div class="calendar-container">
      <bsh-room-bookings-calendar
        [calendarData]="{
          roomCalendarId: roomData?.calendarId,
        }"></bsh-room-bookings-calendar>
    </div>
  </rds-tab>
</rds-tabs>
