import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  RdsButtonModule,
  RdsChipsModule,
  RdsProgressSpinnerModule,
} from '@rds/angular-components';
import { delay, switchMap } from 'rxjs';

import { RoomCardComponent } from '../../components/room-card/room-card.component';
import { FiltersFormService } from '../../components/room-filters-form/filters-form.service';
import { RoomsService } from '../../services/rooms.service';

@Component({
  selector: 'bus-rooms-list',
  imports: [
    RoomCardComponent,
    RouterLink,
    RdsProgressSpinnerModule,
    RdsChipsModule,
    RdsButtonModule,
  ],
  templateUrl: './rooms-list.component.html',
  styleUrl: './rooms-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomsListComponent implements OnInit {
  readonly #roomsService = inject(RoomsService);
  readonly #roomFiltersService = inject(FiltersFormService);
  readonly #breakpoint = inject(BreakpointObserver);
  protected readonly rooms = this.#roomsService.roomsList;
  protected readonly isLoading = computed(
    () =>
      this.#roomsService.isRoomListLoading() ||
      this.#roomsService.isRoomFiltersLoading()
  );
  protected readonly activeFiltersChips =
    this.#roomFiltersService.activeFiltersChips;

  ngOnInit() {
    this.#roomsService
      .getRoomFilters()
      .pipe(
        delay(50),
        switchMap(() =>
          this.#roomsService.getRooms(
            this.#roomFiltersService.getSelectedFormData()
          )
        )
      )
      .subscribe();
  }

  protected closeChip(formControlName: string, formGroup: string | undefined) {
    this.#roomFiltersService.resetFormControl(formControlName, formGroup);
    if (this.#breakpoint.isMatched('(max-width: 768px)')) {
      this.#roomsService
        .getRooms(this.#roomFiltersService.getSelectedFormData())
        .subscribe();
    }
  }

  protected resetFilers() {
    this.#roomFiltersService.resetForm();
    if (this.#breakpoint.isMatched('(max-width: 768px)')) {
      this.#roomsService
        .getRooms(this.#roomFiltersService.getSelectedFormData())
        .subscribe();
    }
  }
}
