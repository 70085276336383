import { Injectable, inject, signal } from '@angular/core';
import { finalize, tap } from 'rxjs';

import {
  BookingRequestDetailsModel,
  GoogleAnalyticsEventCategory,
  GoogleAnalyticsEventName,
  GoogleAnalyticsService,
} from '@bookly/shared';

import { BookingApiService } from './booking-api.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  readonly #bookingApiService = inject(BookingApiService);
  readonly #googleAnalyticsService = inject(GoogleAnalyticsService);

  readonly #upcomingBookingsSignal = signal<BookingRequestDetailsModel[]>([]);
  readonly #isUpcomingBookingsLoadingSignal = signal(false);
  readonly #pastBookingsSignal = signal<BookingRequestDetailsModel[]>([]);
  readonly #isPastBookingsLoadingSignal = signal(false);
  readonly #bookingDetailsSignal = signal<BookingRequestDetailsModel | null>(
    null
  );
  readonly #isBookingDetailsLoadingSignal = signal(false);

  get upcomingBookings() {
    return this.#upcomingBookingsSignal.asReadonly();
  }

  get isUpcomingBookingsLoading() {
    return this.#isUpcomingBookingsLoadingSignal.asReadonly();
  }

  get pastBookings() {
    return this.#pastBookingsSignal.asReadonly();
  }

  get isPastBookingsLoading() {
    return this.#isPastBookingsLoadingSignal.asReadonly();
  }

  get bookingDetails() {
    return this.#bookingDetailsSignal.asReadonly();
  }

  get isBookingDetailsLoading() {
    return this.#isBookingDetailsLoadingSignal.asReadonly();
  }

  public refreshBookingDetails(bookingDetails: BookingRequestDetailsModel) {
    this.#bookingDetailsSignal.set(bookingDetails);
  }

  public getUpcomingBookings() {
    this.#isUpcomingBookingsLoadingSignal.set(true);
    return this.#bookingApiService.getUpcomingBookings().pipe(
      tap(bookings => this.#upcomingBookingsSignal.set(bookings)),
      finalize(() => this.#isUpcomingBookingsLoadingSignal.set(false))
    );
  }

  public getPastBookings() {
    this.#isPastBookingsLoadingSignal.set(true);
    return this.#bookingApiService.getPastBookings().pipe(
      tap(bookings => this.#pastBookingsSignal.set(bookings)),
      finalize(() => this.#isPastBookingsLoadingSignal.set(false))
    );
  }

  public getBookingDetails(bookingId: number) {
    this.#isBookingDetailsLoadingSignal.set(true);
    return this.#bookingApiService.getBookingDetails(bookingId).pipe(
      tap(bookingDetails => this.#bookingDetailsSignal.set(bookingDetails)),
      finalize(() => this.#isBookingDetailsLoadingSignal.set(false))
    );
  }

  public deleteBooking(bookingId: number) {
    this.#isBookingDetailsLoadingSignal.set(true);
    return this.#bookingApiService.deleteBooking(bookingId).pipe(
      tap(() => {
        this.#googleAnalyticsService.trackEvent(
          GoogleAnalyticsEventName.DeleteEvent,
          GoogleAnalyticsEventCategory.BookingRequest,
          `Request ID: ${bookingId}`
        );
      }),
      finalize(() => this.#isBookingDetailsLoadingSignal.set(false))
    );
  }
}
