import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  RdsCardModule,
  RdsIconComponent,
  RdsTooltipModule,
} from '@rds/angular-components';

import { RoomsListModel } from '@bookly/shared';

@Component({
  selector: 'bus-room-card',
  imports: [
    NgOptimizedImage,
    RdsCardModule,
    RdsIconComponent,
    RdsTooltipModule,
  ],
  templateUrl: './room-card.component.html',
  styleUrl: './room-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomCardComponent {
  @Input() public set roomData(value: RoomsListModel) {
    if (value) {
      this.room = value;
      this.features = value.features?.map(item => item.name).join(', ');
    }
  }

  protected room!: RoomsListModel;
  protected features!: string;
}
