@let isMobileScreen = isMobileScreen$ | async;
<div class="card-container">
  <div class="room-card-image">
    @if (roomDetails.photos) {
      @if (isMobileScreen && isMobileScreen.matches) {
        <rds-carousel
          [showButtons]="true"
          [autoplay]="false"
          class="room-card-image">
          @for (carouselItem of roomDetails.photos; track carouselItem.id) {
            <rds-carousel-item>
              <div class="carousel-image">
                <img [ngSrc]="carouselItem.url" alt="Room" fill />
              </div>
            </rds-carousel-item>
          }
        </rds-carousel>
        <div class="gallery-icon">
          <button rds-icon-button (click)="showGallery()">
            <rds-icon
              icon="photo_library"
              namespace="outlined"
              style="color: white"></rds-icon>
          </button>
        </div>
      } @else {
        <img
          tabindex="1"
          alt="room photo"
          (keyup.enter)="showGallery()"
          [ngSrc]="roomDetails.photos[0].url"
          fill
          (click)="showGallery()"
          class="image-cursor" />
      }
    }
  </div>
  <div class="room-details">
    <div class="details-section">
      <div class="details-section">
        <p class="ui-label-s-bold">Location</p>
        <p class="ui-body-l">
          {{ roomDetails.siteName }} {{ roomDetails.buildingName }}
          {{ roomDetails.floorCode }}
        </p>
      </div>
      <div class="details-section">
        <p class="ui-label-s-bold">Space up to</p>
        <p class="ui-body-l">{{ roomDetails.capacity }} people</p>
      </div>
      @if (roomDetails.description) {
        <div class="details-section">
          <p class="ui-label-s-bold">Description</p>
          <p class="ui-body-l">
            {{ roomDetails.description }}
          </p>
        </div>
      }
      <div class="details-section">
        <p class="ui-label-s-bold">Room includes</p>

        <p class="ui-body-l">
          @for (
            feature of roomDetails.features;
            track feature.id;
            let last = $last
          ) {
            {{ feature.name + (last ? '' : ',') }}
          }
        </p>
      </div>
      @if (layouts.length > 0) {
        <rds-form-field labelPosition="top">
          <rds-control-label>
            Select Layout
            <rds-icon icon="info" namespace="outlined"> </rds-icon>
          </rds-control-label>
          <rds-select [formControl]="layoutControl">
            @for (layout of layouts; track layout.layoutDexUUID) {
              <rds-select-option [value]="layout">
                {{ layout.layoutName }}
              </rds-select-option>
            }
          </rds-select>
        </rds-form-field>
        <div class="layout-details">
          @if (layoutControl.value) {
            <div class="layout-info">
              <p class="ui-label-s">
                Layout capacity:
                {{ layoutControl.value.capacity.min }}
                -
                {{ layoutControl.value.capacity.max }}
              </p>
              <p class="ui-label-s">Layout setup time:</p>
              <p class="ui-label-s">
                {{ layoutControl.value.setupTime.before }}
                minutes before -
                {{ layoutControl.value.setupTime.after }}
                minutes after
              </p>
            </div>
          }
          @if (layoutControl.value && layoutControl.value.imageURL) {
            <div class="layout-image">
              <img
                alt="layout-image"
                [ngSrc]="layoutControl.value.imageURL"
                fill />
            </div>
          }
        </div>
      }
    </div>
    <a
      rds-link
      standalone
      size="s"
      href="https://docs.google.com/forms/d/e/1FAIpQLSdGTtWBMH8gDQ774CcmChS7h2OJute_Bc72CiVzose2OkfkvA/viewform"
      target="_blank">
      <div class="external-link">
        Report this room
        <rds-icon namespace="outlined" icon="external_link" />
      </div>
    </a>
  </div>
</div>
