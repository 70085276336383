import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import {
  RdsButtonModule,
  RdsDrawerModule,
  RdsIconComponent,
  RdsSidenavModule,
} from '@rds/angular-components';

import { RoomQueryParams } from '@bookly/shared';

import { RoomsService } from '../../services/rooms.service';
import { FilterFormComponent } from '../room-filters-form/filter-form.component';

@Component({
  selector: 'bus-room-filters-menu',
  imports: [
    CommonModule,
    RdsSidenavModule,
    RdsDrawerModule,
    RdsButtonModule,
    RdsIconComponent,
    FilterFormComponent,
  ],
  templateUrl: './room-filters-menu.component.html',
  styleUrl: './room-filters-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomFiltersMenuComponent {
  readonly #roomService = inject(RoomsService);
  readonly #breakpoint = inject(BreakpointObserver);
  protected isMobileScreen$ = this.#breakpoint.observe('(max-width: 768px)');

  protected showHideClass = signal<'show' | 'hide'>('hide');

  public hideMenu() {
    this.showHideClass.set('hide');
  }

  public showMenu() {
    this.showHideClass.set('show');
  }

  protected applyFilters(filters: RoomQueryParams) {
    this.hideMenu();
    this.#roomService.getRooms(filters).subscribe();
  }
}
