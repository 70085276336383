import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RdsButtonModule,
  RdsCarouselModule,
  RdsDialogService,
  RdsFormFieldModule,
  RdsIconComponent,
  RdsLinkComponent,
} from '@rds/angular-components';

import {
  PhotosComponent,
  RoomDetailsModel,
  RoomLayoutsModel,
} from '@bookly/shared';

@Component({
  selector: 'bus-room-details-card',
  imports: [
    NgOptimizedImage,
    FormsModule,
    RdsFormFieldModule,
    RdsIconComponent,
    ReactiveFormsModule,
    RdsLinkComponent,
    AsyncPipe,
    RdsCarouselModule,
    RdsButtonModule,
  ],
  templateUrl: './room-details-card.component.html',
  styleUrl: './room-details-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDetailsCardComponent {
  readonly #dialogService = inject(RdsDialogService);
  readonly #breakpoint = inject(BreakpointObserver);
  protected isMobileScreen$ = this.#breakpoint.observe('(max-width: 768px)');

  @Input() public roomDetails!: RoomDetailsModel;
  @Input() public set roomLayouts(value: RoomLayoutsModel[] | null) {
    if (value) {
      this.layouts = value;
      const defaultLayout = value.find(
        layout =>
          layout.layoutDexUUID ===
          this.roomDetails.manageSpaceConfiguration?.defaultLayoutId
      );
      if (defaultLayout) {
        this.layoutControl.patchValue(defaultLayout);
      }
    }
  }
  layouts: RoomLayoutsModel[] = [];
  layoutControl = new FormControl<RoomLayoutsModel | null>(null);

  protected showGallery() {
    const dialogRef = this.#dialogService.open(PhotosComponent, {
      size: 'l',
      maxHeight: '90%',
      data: {
        photos: this.roomDetails.photos,
      },
      panelClass: 'dialog-panel',
    });
    dialogRef.afterClosed();
  }
}
