import { Injectable, inject, signal } from '@angular/core';
import { finalize, tap } from 'rxjs';

import {
  BookingRequestApiService,
  BookingRequestCreateModel,
  BookingRequestDetailsModel,
  BookingRequestUpdateModel,
  GoogleAnalyticsEventCategory,
  GoogleAnalyticsEventName,
  GoogleAnalyticsService,
} from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class BookingRequestService {
  readonly #bookingRequestApiService = inject(BookingRequestApiService);
  readonly #googleAnalyticsService = inject(GoogleAnalyticsService);

  readonly #bookingRequestData = signal<BookingRequestDetailsModel | null>(
    null
  );
  readonly #isLoading = signal(false);

  get isLoading() {
    return this.#isLoading.asReadonly();
  }

  get bookingRequestData() {
    return this.#bookingRequestData.asReadonly();
  }

  public createRoomBooking(requestData: BookingRequestCreateModel) {
    this.#isLoading.set(true);
    return this.#bookingRequestApiService.createRoomBooking(requestData).pipe(
      tap(bookingRequest => {
        this.#bookingRequestData.set(bookingRequest);
        this.#googleAnalyticsService.trackEvent(
          GoogleAnalyticsEventName.CreateEvent,
          GoogleAnalyticsEventCategory.BookingRequest,
          `Request ID: ${bookingRequest.id}`
        );
      }),
      finalize(() => this.#isLoading.set(false))
    );
  }

  public updateRoomBooking(requestData: BookingRequestUpdateModel) {
    this.#isLoading.set(true);
    return this.#bookingRequestApiService
      .updateRoomBookingUser(requestData)
      .pipe(
        tap(bookingRequest => {
          this.#bookingRequestData.set(bookingRequest);
          this.#googleAnalyticsService.trackEvent(
            GoogleAnalyticsEventName.EditUser,
            GoogleAnalyticsEventCategory.BookingRequest,
            `Request ID: ${bookingRequest.id}`
          );
        }),
        finalize(() => this.#isLoading.set(false))
      );
  }
}
