import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
  RdsButtonModule,
  RdsIconComponent,
  RdsLinkComponent,
  RdsProgressSpinnerModule,
  RdsTabModule,
} from '@rds/angular-components';
import { formatDate } from 'date-fns';
import { switchMap } from 'rxjs';

import {
  GoogleAnalyticsEventCategory,
  GoogleAnalyticsEventName,
  GoogleAnalyticsService,
  GoogleCalendarParams,
  RoomBookingsCalendarComponent,
  buildParams,
} from '@bookly/shared';

import { RoomDetailsCardComponent } from '../../components/room-details-card/room-details-card.component';
import { FiltersFormService } from '../../components/room-filters-form/filters-form.service';
import { RoomsService } from '../../services/rooms.service';

@Component({
  selector: 'bus-room-details',
  imports: [
    RdsButtonModule,
    RouterLink,
    RdsProgressSpinnerModule,
    RoomBookingsCalendarComponent,
    RdsLinkComponent,
    RdsIconComponent,
    RoomDetailsCardComponent,
    RdsTabModule,
  ],
  templateUrl: './room-details.component.html',
  styleUrl: './room-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDetailsComponent implements OnInit {
  readonly #roomsService = inject(RoomsService);
  readonly #analyticsService = inject(GoogleAnalyticsService);
  readonly #filtersService = inject(FiltersFormService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #destroyRef = inject(DestroyRef);
  protected readonly roomDetails = this.#roomsService.roomDetails;
  protected readonly roomLayouts = this.#roomsService.roomLayouts;
  protected readonly isLoading = this.#roomsService.isRoomDetailsLoading;
  protected readonly filters = this.#filtersService.getSelectedFormData();

  public ngOnInit() {
    this.#activatedRoute.params
      .pipe(
        switchMap(({ roomId }) => this.#roomsService.getRoomData(roomId)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  protected navigateToCalendar() {
    const details = this.roomDetails();
    if (details != null) {
      const format = "yyyyMMdd'T'HHmmss";
      const dates =
        this.filters.fromDate && this.filters.toDate
          ? `${formatDate(this.filters.fromDate.replace('Z', ''), format)}/${formatDate(this.filters.toDate.replace('Z', ''), format)}`
          : undefined;
      this.#analyticsService.trackEvent(
        GoogleAnalyticsEventName.GoogleCalendarBooking,
        GoogleAnalyticsEventCategory.GoogleCalendarBooking,
        `Room ID: ${details.roomDexId}, Room Name: ${details.roomName}, Location: ${details.siteName} ${details.buildingName} ${details.floorCode}`
      );
      window.open(
        this.buildCalendarUrl({ add: details.calendarId, dates: dates }),
        '_blank'
      );
    }
  }

  protected buildCalendarUrl(calendarParams: GoogleCalendarParams): string {
    const params = buildParams(calendarParams);
    return `https://calendar.google.com/calendar/u/0/r/eventedit?${params.toString()}`;
  }
}
