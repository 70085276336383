@let activeFilters = activeFiltersChips();
@let roomsData = rooms();

@if (isLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
}
<p class="ui-label-l-bold">
  {{ roomsData?.length ? roomsData?.length : 0 }} results found
</p>
@if (activeFilters.length > 0) {
  <div class="room-filters">
    @for (chip of activeFilters; track $index) {
      <rds-chip
        size="m"
        closable
        (closeChip)="closeChip(chip.formControl, chip.formGroup)">
        {{ chip.label }}: {{ chip.value }}
      </rds-chip>
    }
    <button rds-text-button (click)="resetFilers()">Reset all filters</button>
  </div>
}
<div class="room-info-cards">
  @for (room of roomsData; track room.id) {
    <bus-room-card [roomData]="room" [routerLink]="room.roomDexId">
    </bus-room-card>
  }
</div>
