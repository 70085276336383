import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import {
  RdsAvatarComponent,
  RdsAvatarImageDirective,
  RdsButtonModule,
  RdsDividerModule,
  RdsDropdownDirective,
  RdsDropdownModule,
  RdsHeaderModule,
  RdsIconComponent,
  RdsMenuModule,
  RdsProgressSpinnerComponent,
} from '@rds/angular-components';

import {
  AuthService,
  DestinationFrontendEnum,
  NotificationsDropdownComponent,
  NotificationsService,
  PushNotificationsService,
} from '@bookly/shared';

import { RoomFiltersMenuComponent } from '../rooms/components/room-filters-menu/room-filters-menu.component';

@Component({
  selector: 'bus-dashboard',
  imports: [
    CommonModule,
    RdsDividerModule,
    NgOptimizedImage,
    NotificationsDropdownComponent,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsHeaderModule,
    RdsDropdownModule,
    RdsButtonModule,
    RouterOutlet,
    RdsIconComponent,
    RdsMenuModule,
    RouterLink,
    RouterLinkActive,
    RoomFiltersMenuComponent,
    RdsProgressSpinnerComponent,
  ],
  templateUrl: './user-dashboard.component.html',
  styleUrl: './user-dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDashboardComponent implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #notificationService = inject(NotificationsService);
  readonly #pushNotificationService = inject(PushNotificationsService);
  protected readonly router = inject(Router);
  protected readonly user = this.#authService.user;
  protected readonly isHost = this.#authService.isHost;
  protected readonly isLoading = this.#authService.isLoginPending;

  @ViewChild('desktopDropdown', { read: RdsDropdownDirective })
  desktopMenuDropdown!: RdsDropdownDirective;

  @ViewChild('mobileDropdown', { read: RdsDropdownDirective })
  mobileMenuDropdown!: RdsDropdownDirective;

  ngOnInit(): void {
    void this.#pushNotificationService.init();
    this.#notificationService
      .getNotifications(DestinationFrontendEnum.User)
      .subscribe();
  }

  protected logout() {
    this.mobileMenuDropdown?.hide();
    this.desktopMenuDropdown?.hide();
    void this.#authService.logout();
  }

  protected navigateToHelpCenter() {
    window.open(
      'https://sites.google.com/contractors.roche.com/bookly/',
      '_blank'
    );
  }

  protected navigateToFeedback() {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSf3faA0qL4TzYymW93_uXK5Og54_iBhwVsCxy3Nj-xk1rRRtw/viewform',
      '_blank'
    );
  }
}
