import { Injectable, inject, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { addHours } from 'date-fns/addHours';
import { formatDate } from 'date-fns/format';
import { toDate } from 'date-fns/toDate';
import { tap } from 'rxjs';

import {
  RoomBuildingsModel,
  RoomLocationsBaseModel,
  RoomQueryParams,
} from '@bookly/shared';

import { RoomsService } from '../../services/rooms.service';

export interface FiltersChipsModel {
  label: string;
  value: string | number;
  formControl: string;
  formGroup?: string;
}

export interface RoomFiltersKeys {
  [key: string]: FormGroup | FormControl;
}

export interface RoomFiltersFormValueModel extends RoomFiltersKeys {
  roomName: FormControl<string | null>;
  fromDate: FormControl<Date | null>;
  fromTime: FormControl<Date | null>;
  toDate: FormControl<Date | null>;
  toTime: FormControl<Date | null>;
  minCapacity: FormControl<number | null>;
  maxCapacity: FormControl<number | null>;
  roomTypes: FormGroup<FiltersCheckboxFormValueModel>;
  roomFeatures: FormGroup<FiltersCheckboxFormValueModel>;
  siteId: FormControl<number | null>;
  buildingId: FormControl<number | null>;
  floorId: FormControl<number | null>;
}

export interface FiltersCheckboxFormValueModel {
  [key: string]: FormControl<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class FiltersFormService {
  readonly #route = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #roomsService = inject(RoomsService);
  readonly #fb = inject(FormBuilder);
  readonly #roomFiltersData = this.#roomsService.roomFiltersData;
  readonly #activeFiltersChips = signal<FiltersChipsModel[]>([]);
  readonly queryParams: Params = this.#route.snapshot.queryParams;

  readonly #filtersForm: FormGroup<RoomFiltersFormValueModel> = this.#fb.group({
    roomName: this.#fb.control<string | null>(
      this.queryParams['roomName'] ?? null
    ),
    fromDate: this.#fb.control<Date | null>(
      this.queryParams['fromDate']
        ? toDate(
            this.queryParams['fromDate']
              .toString()
              .substring(0, this.queryParams['fromDate'].toString().length - 1)
          )
        : null
    ),
    fromTime: this.#fb.control<Date | null>(
      this.queryParams['fromDate']
        ? toDate(
            this.queryParams['fromDate']
              .toString()
              .substring(0, this.queryParams['fromDate'].toString().length - 1)
          )
        : null
    ),
    toDate: this.#fb.control<Date | null>(
      this.queryParams['toDate']
        ? toDate(
            this.queryParams['toDate']
              .toString()
              .substring(0, this.queryParams['toDate'].toString().length - 1)
          )
        : null
    ),
    toTime: this.#fb.control<Date | null>(
      this.queryParams['toDate']
        ? toDate(
            this.queryParams['toDate']
              .toString()
              .substring(0, this.queryParams['toDate'].toString().length - 1)
          )
        : null
    ),
    minCapacity: this.#fb.control<number | null>(
      this.queryParams['minCapacity'] ?? null
    ),
    maxCapacity: this.#fb.control<number | null>(
      this.queryParams['maxCapacity'] ?? null
    ),
    roomTypes: this.#fb.group({}),
    roomFeatures: this.#fb.group({}),
    siteId: this.#fb.control<number | null>(
      this.queryParams['siteId'] ? +this.queryParams['siteId'] : null
    ),
    buildingId: this.#fb.control<number | null>(
      this.queryParams['buildingId'] ? +this.queryParams['buildingId'] : null
    ),
    floorId: this.#fb.control<number | null>(
      this.queryParams['floorId'] ? +this.queryParams['floorId'] : null
    ),
  });
  readonly #availableOnControl = this.#fb.nonNullable.control<boolean>(false);

  #buildings = signal<RoomBuildingsModel[]>([]);
  #floors = signal<RoomLocationsBaseModel[]>([]);

  get filtersForm() {
    return this.#filtersForm;
  }

  get availableOnControl() {
    return this.#availableOnControl;
  }

  get buildings() {
    return this.#buildings.asReadonly();
  }

  get floors() {
    return this.#floors.asReadonly();
  }

  get roomFiltersData() {
    return this.#roomFiltersData;
  }

  get activeFiltersChips() {
    return this.#activeFiltersChips.asReadonly();
  }

  get siteIdValueChanges() {
    return this.#filtersForm.controls.siteId.valueChanges.pipe(
      tap(siteId => {
        const roomFiltersData = this.#roomFiltersData();
        if (roomFiltersData) {
          const buildings = roomFiltersData.locations.find(
            site => site.id === siteId
          )?.buildings;

          this.#buildings.set(buildings ?? []);
        }
        this.#filtersForm.controls.buildingId.patchValue(null, {
          emitEvent: false,
        });
        this.#filtersForm.controls.floorId.patchValue(null, {
          emitEvent: false,
        });
      })
    );
  }

  get buildingIdValueChanges() {
    return this.#filtersForm.controls.buildingId.valueChanges.pipe(
      tap(buildingId => {
        const roomFiltersData = this.#roomFiltersData();
        const buildings = this.#buildings();
        if (roomFiltersData && buildings) {
          const floors = buildings.find(
            building => building.id === buildingId
          )?.floors;
          this.#floors.set(floors ?? []);
        }
        this.#filtersForm.controls.floorId.patchValue(null, {
          emitEvent: false,
        });
      })
    );
  }

  get availableOnControlValueChanges() {
    return this.#availableOnControl.valueChanges.pipe(
      tap(controlValue => {
        if (controlValue) {
          const fromDate = new Date();
          const toDate = addHours(fromDate, 1);
          this.#filtersForm.controls.fromDate.patchValue(fromDate);
          this.#filtersForm.controls.fromTime.patchValue(fromDate);
          this.#filtersForm.controls.toDate.patchValue(toDate);
          this.#filtersForm.controls.toTime.patchValue(toDate);
        } else {
          this.#filtersForm.controls.fromDate.patchValue(null);
          this.#filtersForm.controls.fromTime.patchValue(null);
          this.#filtersForm.controls.toDate.patchValue(null);
          this.#filtersForm.controls.toTime.patchValue(null);
        }
      })
    );
  }

  public populateRoomFilters() {
    const roomFiltersData = this.#roomFiltersData();

    if (roomFiltersData) {
      roomFiltersData.roomTypes.forEach(roomType => {
        const typeControl = this.#fb.nonNullable.control(false);
        this.#filtersForm.controls.roomTypes.addControl(
          roomType.name,
          typeControl,
          { emitEvent: false }
        );
      });
      roomFiltersData.roomFeatures.roomMustInclude.forEach(roomFeature => {
        const featureControl = this.#fb.nonNullable.control(false);
        this.#filtersForm.controls.roomFeatures.addControl(
          roomFeature.id.toString(),
          featureControl,
          { emitEvent: false }
        );
      });
      roomFiltersData.roomFeatures.roomTypeSetup.forEach(roomFeature => {
        const featureControl = this.#fb.nonNullable.control(false);
        this.#filtersForm.controls.roomFeatures.addControl(
          roomFeature.id.toString(),
          featureControl,
          { emitEvent: false }
        );
      });
      if (this.queryParams['roomTypes']) {
        const roomTypes = this.queryParams['roomTypes'].split(',');
        roomTypes.forEach((roomType: string) => {
          this.#filtersForm.controls.roomTypes.controls[roomType].patchValue(
            true,
            { emitEvent: false }
          );
        });
      }

      if (this.queryParams['featureIds']) {
        const featureIds = this.queryParams['featureIds'].split(',');
        featureIds.forEach((featureId: string) => {
          this.#filtersForm.controls.roomFeatures.controls[
            featureId
          ].patchValue(true, { emitEvent: false });
        });
      }

      if (this.queryParams['siteId']) {
        const buildings = roomFiltersData.locations.find(
          site => site.id === +this.queryParams['siteId']
        )?.buildings;

        this.#buildings.set(buildings ?? []);
      }
      if (this.queryParams['buildingId']) {
        const floors = this.buildings().find(
          building => building.id === +this.queryParams['buildingId']
        )?.floors;
        this.#floors.set(floors ?? []);
      }
    }
  }

  public getSelectedFormData(): RoomQueryParams {
    const filtersChips: FiltersChipsModel[] = [];
    const selectedFilters: RoomQueryParams = {};
    const formValue = this.#filtersForm.value;
    const roomTypes = formValue.roomTypes;
    const roomFeatures = formValue.roomFeatures;
    const selectedRoomTypes: string[] = [];
    const selectedRoomFeaturesIds: number[] = [];

    if (formValue.roomName) {
      selectedFilters['roomName'] = formValue.roomName;
      filtersChips.push({
        label: 'Name',
        value: formValue.roomName,
        formControl: 'roomName',
      });
    }

    if (formValue.siteId) {
      selectedFilters['siteId'] = formValue.siteId;
      filtersChips.push({
        label: 'City',
        value:
          this.#roomFiltersData()?.locations.find(
            location => location.id === formValue.siteId
          )?.name ?? '',
        formControl: 'siteId',
      });
    }

    if (formValue.buildingId) {
      selectedFilters['buildingId'] = formValue.buildingId;
      filtersChips.push({
        label: 'Building',
        value:
          this.#buildings()?.find(
            building => building.id === formValue.buildingId
          )?.name ?? '',
        formControl: 'buildingId',
      });
    }

    if (formValue.floorId) {
      selectedFilters['floorId'] = formValue.floorId;
      filtersChips.push({
        label: 'Floor',
        value:
          this.#floors()?.find(floor => floor.id === formValue.floorId)?.name ??
          '',
        formControl: 'floorId',
      });
    }

    if (formValue.minCapacity) {
      selectedFilters['minCapacity'] = formValue.minCapacity;
      filtersChips.push({
        label: 'Min. capacity',
        value: formValue.minCapacity,
        formControl: 'minCapacity',
      });
    }

    if (formValue.maxCapacity) {
      selectedFilters['maxCapacity'] = formValue.maxCapacity;
      filtersChips.push({
        label: 'Max. capacity',
        value: formValue.maxCapacity,
        formControl: 'maxCapacity',
      });
    }

    if (formValue.fromDate) {
      // ToDo check if date filter is behaving correctly, since the Z is added in post not during formating (we are adding z to local date)
      const timeFrom = formValue.fromTime
        ? formatDate(formValue.fromTime, 'HH:mm:00')
        : '00:00:00';
      const dateString = formatDate(
        formValue.fromDate,
        "yyyy-MM-dd'T'" + timeFrom
      );
      selectedFilters['fromDate'] = dateString + 'Z';
      filtersChips.push({
        label: 'Date from',
        value: formatDate(new Date(dateString), 'dd/MM/yyy HH:mm'),
        formControl: 'fromDate',
      });
    }

    if (formValue.toDate) {
      const timeFrom = formValue.toTime
        ? formatDate(formValue.toTime, 'HH:mm:59')
        : '23:59:59';
      const dateString = formatDate(
        formValue.toDate,
        "yyyy-MM-dd'T'" + timeFrom
      );
      selectedFilters['toDate'] = dateString + 'Z';
      filtersChips.push({
        label: 'Date to',
        value: formatDate(new Date(dateString), 'dd/MM/yyy HH:mm'),
        formControl: 'toDate',
      });
    }

    if (roomTypes) {
      Object.entries(roomTypes).forEach(([key, value]) => {
        if (value) {
          selectedRoomTypes.push(key);
          filtersChips.push({
            label: 'Room type',
            value: key,
            formControl: key,
            formGroup: 'roomTypes',
          });
        }
      });
    }

    if (roomFeatures) {
      Object.entries(roomFeatures).forEach(([key, value]) => {
        if (value) {
          selectedRoomFeaturesIds.push(+key);
          const roomMustIncludeItem =
            this.#roomFiltersData()?.roomFeatures.roomMustInclude.find(
              feature => feature.id === +key
            );
          const roomTypeSetupItem =
            this.#roomFiltersData()?.roomFeatures.roomTypeSetup.find(
              feature => feature.id === +key
            );

          if (roomTypeSetupItem) {
            filtersChips.push({
              label: 'Room setup',
              value: roomTypeSetupItem.name,
              formControl: key,
              formGroup: 'roomFeatures',
            });
          }

          if (roomMustIncludeItem) {
            filtersChips.push({
              label: 'Room includes',
              value: roomMustIncludeItem.name,
              formControl: key,
              formGroup: 'roomFeatures',
            });
          }
        }
      });
    }

    if (selectedRoomTypes.length > 0) {
      selectedFilters['roomTypes'] = selectedRoomTypes;
    }

    if (selectedRoomFeaturesIds.length > 0) {
      selectedFilters['featureIds'] = selectedRoomFeaturesIds;
    }

    this.#activeFiltersChips.set(filtersChips);

    void this.#router.navigate([], {
      relativeTo: this.#route,
      queryParams: this.#buildRouterQueryParams(selectedFilters),
      queryParamsHandling: 'replace',
      skipLocationChange: false,
    });

    return selectedFilters;
  }

  public resetForm() {
    this.#filtersForm.reset();
    this.#availableOnControl.reset();
  }

  public resetFormControl(formControlName: string, formGroup?: string) {
    if (
      formGroup &&
      this.#filtersForm.controls[formGroup] instanceof FormGroup
    ) {
      this.#filtersForm.controls[formGroup].controls[formControlName].reset();
    } else {
      this.#filtersForm.controls[formControlName].reset();
      if (formControlName === 'fromDate') {
        this.#filtersForm.controls['fromTime'].reset();
      }
      if (formControlName === 'toDate') {
        this.#filtersForm.controls['toTime'].reset();
      }
    }

    this.getSelectedFormData();
  }

  #buildRouterQueryParams(params: RoomQueryParams): Params {
    const queryParams: Params = { ...params };
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value instanceof Array) {
        queryParams[key] = value.join(',');
      }
    });
    return queryParams;
  }
}
