<div
  class="container form-wrapper booking-details-form"
  [formGroup]="bookingDetailsForm">
  <div class="row">
    <div class="col-12 lmb-2">
      <p class="ui-heading-2-bold">Select booking parameters</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <rds-form-field labelPosition="top">
        <rds-control-label> Event name </rds-control-label>
        <input rdsInput formControlName="eventName" />
        @if (bookingDetailsForm.controls.eventName.errors) {
          <rds-control-error>This field is required</rds-control-error>
        }
      </rds-form-field>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-4">
      <rds-form-field labelPosition="top">
        <rds-control-label>Event Start - End Date</rds-control-label>
        <rds-date-range-input [rangePicker]="rangePicker" [min]="minDate">
          <input
            rdsStartDate
            placeholder="Start date"
            formControlName="startDate" />
          <input rdsEndDate placeholder="End date" formControlName="endDate" />
        </rds-date-range-input>
        <rds-datepicker-toggle rds-control-suffix></rds-datepicker-toggle>
        <rds-date-range-picker #rangePicker showToggle="false">
        </rds-date-range-picker>
        @if (bookingDetailsForm.controls.startDate.invalid) {
          <rds-control-error> Date cannot be from the past </rds-control-error>
        }
      </rds-form-field>
    </div>
    <div class="col-5">
      <div class="timepickers-container">
        <rds-form-field labelPosition="top">
          <rds-control-label>Start time</rds-control-label>
          <input
            rdsInput
            [rdsTimepicker]="startPicker"
            formControlName="startHour" />
          <rds-timepicker #startPicker />
          <rds-timepicker-clear
            rds-control-suffix
            [forTimepicker]="startPicker" />
          @if (bookingDetailsForm.errors?.['timeNotValid']) {
            <rds-control-error>
              Start time should be before end time
            </rds-control-error>
          } @else if (bookingDetailsForm.errors?.['timeFromPast']) {
            <rds-control-error>
              Event cannot start in the past
            </rds-control-error>
          } @else if (bookingDetailsForm.errors?.['durationTooShort']) {
            <rds-control-error>
              Meeting duration is too short
            </rds-control-error>
          } @else if (bookingDetailsForm.errors?.['durationTooLong']) {
            <rds-control-error>
              Meeting duration is too long
            </rds-control-error>
          } @else if (bookingDetailsForm.errors?.['blockingOverlap']) {
            <rds-control-error>
              Meeting overlaps with blocking time
            </rds-control-error>
          }
        </rds-form-field>
        <rds-form-field labelPosition="top">
          <rds-control-label>End time</rds-control-label>
          <input
            rdsInput
            [rdsTimepicker]="endPicker"
            formControlName="endHour" />
          <rds-timepicker #endPicker />
          <rds-timepicker-clear
            rds-control-suffix
            [forTimepicker]="endPicker" />
          @if (bookingDetailsForm.errors?.['timeNotValid']) {
            <rds-control-error>
              End time should be after start time
            </rds-control-error>
          }
        </rds-form-field>
      </div>
    </div>
    <div class="col-3">
      <rds-form-field labelPosition="top">
        <rds-control-label>No. of People</rds-control-label>
        <input rdsInput type="number" formControlName="numberOfPeople" />
        @if (bookingDetailsForm.controls.numberOfPeople.errors?.['max']) {
          <rds-control-error>
            Maximum capacity:
            {{
              bookingDetailsForm.controls.roomLayoutUUID.value?.capacity?.max
                ? bookingDetailsForm.controls.roomLayoutUUID.value?.capacity
                    ?.max
                : maxRoomCapacity
            }}
          </rds-control-error>
        }
        @if (bookingDetailsForm.controls.numberOfPeople.errors?.['min']) {
          <rds-control-error>
            Minimum capacity:
            {{
              bookingDetailsForm.controls.roomLayoutUUID.value?.capacity?.min
                ? bookingDetailsForm.controls.roomLayoutUUID.value?.capacity
                    ?.min
                : minRoomCapacity
            }}
          </rds-control-error>
        }
      </rds-form-field>
    </div>
  </div>
  <div class="row lmb-2">
    <div class="col-12">
      <p class="ui-heading-2-bold">Additional parameters</p>
    </div>
  </div>
  <div class="row">
    <div class="col-5">
      <div class="switch-wrapper">
        <p class="ui-label-m-bold c-700-grey-1">Request Additional Services</p>
        <div>
          <rds-switch formControlName="cateringService" labelPosition="after"
            >Catering Service
          </rds-switch>
        </div>
        <div>
          <rds-switch formControlName="lockerService" labelPosition="after"
            >Room locking Service</rds-switch
          >
          @if (bookingDetailsForm.controls.lockerService.value) {
            <p class="ui-label-m-bold">
              The request should be send no later than 48 hours in advance of
              the event and should last longer than 24 hours otherwise it may be
              rejected.
            </p>
          }
        </div>
        <div>
          <rds-switch formControlName="furnitureService" labelPosition="after"
            >Furniture Service</rds-switch
          >
        </div>
      </div>
    </div>
    @if (layouts().length > 0) {
      <div class="col-5 lmt-s-2">
        <rds-form-field labelPosition="top">
          <rds-control-label>
            Select Layout
            <rds-icon
              [rdsTooltip]="'Tooltip message'"
              icon="info"
              namespace="outlined">
            </rds-icon>
          </rds-control-label>
          <rds-select formControlName="roomLayoutUUID">
            @for (layout of layouts(); track layout.layoutDexUUID) {
              <rds-select-option [value]="layout">
                {{ layout.layoutName }}
              </rds-select-option>
            }
          </rds-select>
        </rds-form-field>
        <div class="layout-details">
          @if (bookingDetailsForm.controls.roomLayoutUUID.value) {
            <p class="ui-label-s">
              Layout capacity:
              {{
                bookingDetailsForm.controls.roomLayoutUUID.value.capacity.min
              }}
              -
              {{
                bookingDetailsForm.controls.roomLayoutUUID.value.capacity.max
              }}
            </p>
            <p class="ui-label-s">Layout setup time:</p>
            <p class="ui-label-s">
              {{
                bookingDetailsForm.controls.roomLayoutUUID.value.setupTime
                  .before
              }}
              minutes before -
              {{
                bookingDetailsForm.controls.roomLayoutUUID.value.setupTime.after
              }}
              minutes after
            </p>
          }
        </div>
      </div>
      <div class="col-2">
        @if (
          bookingDetailsForm.controls.roomLayoutUUID.value &&
          bookingDetailsForm.controls.roomLayoutUUID.value.imageURL
        ) {
          <div class="layout-image">
            <img
              alt="layout-image"
              [ngSrc]="
                bookingDetailsForm.controls.roomLayoutUUID.value.imageURL
              "
              fill />
          </div>
        }
      </div>
    }
  </div>
  <div class="row lmt-3 lmb-2">
    <div class="col-12">
      <p class="ui-heading-2-bold">Budgeting</p>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <rds-form-field labelPosition="top">
        <rds-control-label>WBS</rds-control-label>
        <input rdsInput formControlName="wbsCode" />
      </rds-form-field>
    </div>
    <div class="col-6">
      <rds-form-field labelPosition="top">
        <rds-control-label>Cost Center</rds-control-label>
        <input rdsInput maxlength="10" formControlName="costCenter" />
        @if (bookingDetailsForm.controls.costCenter.invalid) {
          <rds-control-error
            >Cost center should be 10 digits number</rds-control-error
          >
        }
      </rds-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12 buttons-container">
      <button rds-secondary-button size="l" (click)="cancel()">Cancel</button>
      <button rds-primary-button size="l" (click)="confirm()">Confirm</button>
    </div>
  </div>
</div>
